// src/components/Header.js
import React, { useEffect } from "react";
import { Navbar } from "flowbite-react";
import { auth } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import useUser from '../hooks/useUser';
import UserDropdown from './UserDropdown';
import './AppNav.css';

const AppNav = () => {
  const [user] = useAuthState(auth);
  const { loggedInUser, fetchUser } = useUser();

  useEffect(() => {
    if (user) {
      fetchUser(user.uid);
    }
  }, [user, fetchUser]);

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Home</span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link href="#">About</Navbar.Link>
        <Navbar.Link href="/pricing">Pricing</Navbar.Link>
      </Navbar.Collapse>
      <Navbar.Collapse className="user-collapse">
        {user ? <UserDropdown user={loggedInUser} /> : <Navbar.Link href="/login">Login</Navbar.Link>}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNav;
