import { Button, Drawer } from "flowbite-react";
import React, { useState, useContext, useEffect } from "react";
import ApiHandler from "../util/ApiHandler";
import { MonthContext } from "../context/MonthContext";
import Chart from "chart.js/auto";
import { auth } from '../firebase';
import BTToast from './BTToast';

const BudgetOverview = ({ expenses, chartRef, isOpen, setIsOpen }) => {
  const { currentMonth } = useContext(MonthContext);

  const [totalExpenses, setTotalExpenses] = useState(0);
  const [income, setIncome] = useState(0);
  const [savings, setSavings] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleIncomeChange = (e) => {
    setUnsavedChanges(true)
    setIncome(parseFloat(e.target.value) || 0);
  };

  const handleSavingsChange = (e) => {
    setUnsavedChanges(true)
    setSavings(parseFloat(e.target.value) || 0);
  };

  const handleSave = async () => {
    try {
      await ApiHandler.post('/budget/post', { income, savings, month: currentMonth, userId: auth.currentUser.uid });
      setShowSuccess(true);
      setUnsavedChanges(false)
    } catch (err) {
      setShowError(true);
      alert(err);
    }
  };

  useEffect(() => {
    const fetchSpending = async () => {
      try {
        const SPENDING = await ApiHandler.post('/budget/get', { month: currentMonth, userId: auth.currentUser.uid });
        setIncome(SPENDING.income || 0);
        setSavings(SPENDING.savings || 0);
      } catch (error) {
        console.error("Failed to fetch budget:", error);
      }
    };

    fetchSpending();
  }, [currentMonth]); // Re-run the effect when currentMonth changes

  useEffect(() => {
    // Calculate total expenses
    const total = expenses.reduce((acc, expense) => acc + expense.amount, 0);
    setTotalExpenses(total);
  }, [expenses]);

  const drawPieChart = () => {
    if (!chartRef.current) return;

    const spendingDistribution = calculateSpendingDistribution(expenses);

    const labels = Object.keys(spendingDistribution);
    const data = Object.values(spendingDistribution);

    if (chartRef.current.chart) {
      // Destroy existing chart if it exists
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(chartRef.current, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Expense Distribution",
            data: data,
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Colors for each category
          },
        ],
      },
    });
  };

  useEffect(() => {
    // Draw pie chart when component mounts
    if (chartRef.current) {
      drawPieChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses]);

  const calculateSpendingDistribution = (expenses) => {
    const distribution = expenses.reduce((accumulator, expense) => {
      accumulator[expense.expenseType] = (accumulator[expense.expenseType] || 0) + expense.amount;
      return accumulator;
    }, {});
    distribution["investment"] = (distribution["investment"] || 0) + savings;
    return distribution;
  }

  const remainingBudget = income - savings - totalExpenses;

  return (
    <>
      <Drawer
        edge
        open={isOpen}
        onClose={handleClose}
        position="left"
        className="p-5 h-auto"
      >
        {showSuccess && <BTToast showToast={setShowSuccess} type="success" message={'Changes saved'} />}
        {showError && <BTToast showToast={setShowError} type="failure" message={'Failed to save changes'} />}
        <div className="budget mx-10 my-10 p-3 bg-white border border-gray-300 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Budget Overview</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700">Income:</label>
              <input
                type="number"
                value={income}
                onChange={handleIncomeChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-gray-700">Savings:</label>
              <input
                type="number"
                value={savings}
                onChange={handleSavingsChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-gray-700">Total Expenses:</label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 focus:outline-none sm:text-sm">
                {totalExpenses.toFixed(2)}
              </p>
            </div>
            <div>
              <label className="block text-gray-700 font-semibold">
                Remaining Budget:
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 focus:outline-none sm:text-sm">
                {remainingBudget.toFixed(2)}
              </p>
            </div>
          </div>
          {unsavedChanges && (
            <div className="mt-4">
              <Button color={"blue"} className="mt-4" onClick={handleSave}>
                Save Changes
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default BudgetOverview;
