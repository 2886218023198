/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { auth } from '../firebase';
import BTToast from './BTToast';

function Home() {
  const { user } = useAuth();
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    !!success && setShowSuccessToast(true);
  }, []);

  const handleSignOut = async (e) => {
    e.preventDefault();
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className='mw-10'>
        {showSuccessToast && <BTToast showToast={setShowSuccessToast} type="success" message={'Success'} />}
      </div>
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-4 text-center">Welcome to the Budget Tracker</h1>
        {user ? (
          <div>
            <p className="text-gray-600 mb-4">You are logged in as {user.email}</p>
            <Link to="/budget-tracker">
              <button className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300 mb-2">
                Open Budget Tracker
              </button>
            </Link>
            <a
              href="#"
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300 mb-2"
              onClick={handleSignOut}
              style={{ float: "right" }}
            >
              Sign Out
            </a>
          </div>
        ) : (
          <div>
            <p className="text-gray-600 mb-4">Please log in or register to access the Budget Tracker.</p>
            <div className="flex justify-between">
              <Link to="/login">
                <button className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300">
                  Login
                </button>
              </Link>
              <Link to="/register">
                <button className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300">
                  Register
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
