import React, { useEffect, useState } from 'react';
import useUser from '../hooks/useUser';
import ApiHandler from '../util/ApiHandler';

function PricingPage() {
  const { loggedInUser } = useUser();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const createSession = async () => {
      if (loggedInUser) {
        const response = await ApiHandler.post(`/session/${loggedInUser.firebaseUid}`);
        setClientSecret(response?.client_secret);
      }
    };

    createSession();
  }, [loggedInUser]);

  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
      publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
      customer-session-client-secret={clientSecret}
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;