import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import ApiHandler from '../util/ApiHandler';

const Subscription = () => {
  const [user] = useAuthState(auth);
  const [priceId, setPriceId] = useState('');

  const handleSubscription = async () => {
    if (!user) {
      alert('Please log in first');
      return;
    }

    try {
      const token = await user.getIdToken();
      await ApiHandler.post('/subscription', {
        userId: user.uid,
        priceId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Subscription created successfully');
    } catch (error) {
      console.error('Error creating subscription', error);
      alert('Error creating subscription');
    }
  };

  return (
    <div>
      <input
        type="text"
        value={priceId}
        onChange={(e) => setPriceId(e.target.value)}
        placeholder="Price ID"
      />
      <button onClick={handleSubscription}>Subscribe</button>
    </div>
  );
};

export default Subscription;
