// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import {useAuth} from "../context/AuthProvider";
const PrivateRoute = ({ children }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>; // or a loading spinner
    }

    return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
