import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from "react-router-dom";
import './Login.css'
import BTToast from './BTToast';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setShowSuccess(true);
      navigate("/budget-tracker");
    } catch (error) {
      console.error('Error logging in', error);
      setShowError(true);
    }
  };

  return (
    <div className="login-container">
      {showSuccess && <BTToast showToast={setShowSuccess} type="success" message={'Login success!'} />}
      {showError && <BTToast showToast={setShowError} type="failure" message={'Error logging in'} />}
      <div className="login-logo">
        <img className='logo-img' src="../BudgetCover.png" alt="Budget Tracker Logo" />
      </div>
      <h2>Sign in to Budget Tracker</h2>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-options">
          <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
        </div>
        <button type="submit" className="btn-login">Sign in</button>
      </form>
      <div className="alternative-login">
        <Link to="/register" className="sign-up-link">New to Budget Tracker? Create an account</Link>
      </div>
    </div>
  );
};

export default Login;
