import React, { createContext, useState } from 'react';

export const MonthContext = createContext();

export const MonthProvider = ({ children }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // JavaScript months are 0-based

  return (
    <MonthContext.Provider value={{ currentMonth, setCurrentMonth }}>
      {children}
    </MonthContext.Provider>
  );
};
