import React, { useState, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import BudgetOverview from './components/BudgetOverview';
import AddExpense from './components/AddExpense';
import CategoryTable from './components/CategoryTable';
import ApiHandler from './util/ApiHandler';
import { MonthProvider } from './context/MonthContext';
import { CATEGORIES } from "./constants";
import { auth } from './firebase';
import useExpenses from './hooks/useExpenses';

function App() {
  const { expenses, setExpenses } = useExpenses();
  
  const [expenseModalIsOpen, setExpenseModalIsOpen] = useState(false);
  const [category, setCategory] = useState('Bills');
  const chartRef = useRef(null);
  const [isBudgetOpen, setIsBudgetOpen] = useState(true);

  const addExpense = async (expense) => {
    await ApiHandler.post(`/expenses/${auth.currentUser.uid}`, expense);
    setExpenses([...expenses, expense]);
  };

  return (
    <MonthProvider>
      <div className="App">
        <Header setIsOpen={setIsBudgetOpen} />
        <div style={{ minWidth: "fit-content" }} className="flex container mx-auto p-4">
          <div style={{ position: "absolute", left: "1%" }}>
            <BudgetOverview expenses={expenses} chartRef={chartRef} isOpen={isBudgetOpen} setIsOpen={setIsBudgetOpen} />
          </div>
          <div>
            <AddExpense onAdd={addExpense} isOpen={expenseModalIsOpen} setIsOpen={setExpenseModalIsOpen} category={category} setCategory={setCategory} />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {CATEGORIES.map((category) => (
                <CategoryTable key={category} category={category} expenses={expenses} setExpenses={setExpenses} setIsOpen={setExpenseModalIsOpen} setCategory={setCategory} />
              ))}
              <div className="pie-chart">
                <h2 className="text-lg font-semibold mb-4">50/30/20 comparison</h2>
                <canvas ref={chartRef} id="pie-chart" width="400" height="400"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MonthProvider>
  );
}

export default App;
