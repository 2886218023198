import React, { useState, useEffect } from 'react';
import { Avatar, Dropdown } from "flowbite-react";
import { auth } from '../firebase';
import AvatarModal from './AvatarModal';
import ApiHandler from '../util/ApiHandler';
import BTToast from './BTToast';
import { HiOutlineInformationCircle } from "react-icons/hi";

const UserDropdown = ({ user }) => {
  const [avatarImg, setAvatarImg] = useState('');
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [showAvatarModal, setShowAvatarModal] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const getAvatar = async () => {
      const response = await ApiHandler.get(`/avatar/${user.id}`);
      setAvatarImg(response);
    };
    user?.id && getAvatar();
  }, [user?.id]);

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const saveAvatar = async () => {
    try {
      const response = await ApiHandler.post('avatar', {
        userId: user.id,
        avatar: selectedAvatar,
      });

      if (response.ok) {
        setShowSuccess(true);
        setAvatarImg(selectedAvatar);
        setShowAvatarModal(false);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
      setShowError(true);
    }
  };

  return (
    <div className="flex md:order-2">
      <div>
        {showSuccess && <BTToast showToast={setShowSuccess} type="success" message={'Avatar updated successfully!'} />}
        {showError && <BTToast showToast={setShowError} type="failure" message={'Failed to update avatar'} />}
      </div>
      <Dropdown
        arrowIcon={false}
        inline
        label={
          <Avatar alt="User settings" img={avatarImg} rounded />
        }
      >
        <Dropdown.Header>
          {user?.firstName && <span className="block text-sm">{`${user.firstName} ${user.lastName}`}</span>}
          <span className="block truncate text-sm font-medium">{user?.email}</span>
        </Dropdown.Header>
        <Dropdown.Item href="https://billing.stripe.com/p/login/test_3cs4i06PrayL2VG4gg" target="_blank">Stripe User Portal</Dropdown.Item>
        <Dropdown.Item onClick={() => setShowAvatarModal(true)}>Change Avatar</Dropdown.Item>
        <Dropdown.Item disabled>
          <div className="flex items-center">
            Dashboard
            <span className="ml-2 tooltip tooltip-bottom" data-tip="Coming Soon">
              <HiOutlineInformationCircle className="w-4 h-4" />
            </span>
          </div>
        </Dropdown.Item>
        <Dropdown.Item disabled>
          <div className="flex items-center">
            Settings
            <span className="ml-2 tooltip tooltip-bottom" data-tip="Coming Soon">
              <HiOutlineInformationCircle className="w-4 h-4" />
            </span>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
      </Dropdown>
      <AvatarModal isOpen={showAvatarModal} setIsOpen={setShowAvatarModal} setSelectedAvatar={handleAvatarSelect} saveAvatar={saveAvatar} />
    </div>
  );
};

export default UserDropdown;
