/**
 * Get the month from an expense date.
 * @param {Object} expense - The expense object.
 * @param {Date} expense.date - The date of the expense.
 * @param {boolean} [asString=false] - Whether to return the month as a string.
 * @returns {number|string} - The month of the expense (1-12) or month name if asString is true.
 */
export const getExpenseMonth = (expense, asString = false) => {
    const date = new Date(expense.date);
    const monthNumber = date.getMonth() + 1; // getMonth() returns month from 0-11
  
    if (asString) {
      return date.toLocaleString('default', { month: 'long' });
    }
  
    return monthNumber;
  };
  