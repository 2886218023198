import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import PricingPage from "./components/PricingPage";
import BudgetApp from "./components/BudgetApp";
import Register from "./components/Register";
import Login from "./components/Login";
import Subscription from "./components/Subscription";
import withAuth from "./hoc/withAuth";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthProvider";
import AppNav from "./components/AppNav";

function App() {
  return (
    <AuthProvider>
      <AppNav />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/budget-tracker"
            element={
              <PrivateRoute>
                <BudgetApp />
              </PrivateRoute>
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/subscription" element={withAuth(Subscription)} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
