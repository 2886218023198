import React from 'react';
import { useNavigate } from "react-router-dom";
import useSubscriptions from "../hooks/useSubscriptions";
import BudgetTracker from '../BudgetTracker'

const BudgetApp = () => {
  const { subscription } = useSubscriptions()
  const navigate = useNavigate();

  return (
    <>
      {subscription.status === 'active' ? <BudgetTracker /> : navigate("/pricing")}
    </>
  );
};

export default BudgetApp;
