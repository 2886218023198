// src/components/CategoryTable.js
import React, { useContext, useEffect } from 'react';
import ExpenseTable from './ExpenseTable';
import { MonthContext } from '../context/MonthContext';
import { getExpenseMonth } from '../util/dateUtils';
import ApiHandler from '../util/ApiHandler';
import { HiOutlinePlusCircle } from "react-icons/hi";
import { auth } from '../firebase';

const CategoryTable = ({ expenses, category, setExpenses, setIsOpen, setCategory }) => {
  const { currentMonth } = useContext(MonthContext);
  const filteredExpenses = expenses.filter(
    (expense) => expense.category === category && getExpenseMonth(expense) === currentMonth
  );

  // couldn't do this in App.js because of the way the context is set up, so i'm doing it here instead
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const expenses = await ApiHandler.get(`/expenses/${auth.currentUser.uid}`);
        setExpenses(expenses.filter((expense) => getExpenseMonth(expense) === currentMonth))
      } catch (error) {
        console.error('Failed to fetch budget:', error);
      }
    };

    fetchExpenses();
  }, [currentMonth, setExpenses]); // Re-run the effect when currentMonth changes

  const handleClickAddButton = () => {
    setCategory(category);
    setIsOpen(true);
  };

  return (
    <div className="category-table p-4 bg-white border border-gray-300 rounded-lg shadow max-h-96 overflow-y-auto">
      <div className="flex justify-center items-baseline gap-2">
        <h2 className="text-lg font-semibold mb-4">{category}</h2>
        <HiOutlinePlusCircle onClick={handleClickAddButton} style={{position: "relative", top: "3px", cursor: "pointer", color: "#0796a4"}} size="21" />
      </div>
      <ExpenseTable expenses={filteredExpenses} />
    </div>
  );
};

export default CategoryTable;
