import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, TextInput, Select } from 'flowbite-react';
import { MonthContext } from '../context/MonthContext';
import { ExpenseType } from '../util/ExpenseType';

const AddExpense = ({ onAdd, isOpen, setIsOpen, category, setCategory }) => {
  const { currentMonth } = useContext(MonthContext);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [disableRadioGroup, setDisableRadioGroup] = useState(false);

  const radioOptions = Object.entries(ExpenseType).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  useEffect(() => {
    switch (category) {
      case 'Bills':
        setExpenseType('need');
        setDisableRadioGroup(true);
        break;
      case 'Food':
        setExpenseType('need');
        setDisableRadioGroup(false);
        break;
      case 'Groceries':
        setExpenseType('need');
        setDisableRadioGroup(false);
        break;
      case 'Entertainment':
        setExpenseType('want');
        setDisableRadioGroup(true);
        break;
      case 'Other':
        setExpenseType('');
        setDisableRadioGroup(false);
        break;
      default:
        setExpenseType('');
        setDisableRadioGroup(false);
    }
  }, [category]);

  const handleSubmit = (e) => {
    const year = new Date().getFullYear()
    e.preventDefault();
    if (!description || !amount || !category || !expenseType) {
      alert('missing required fields')
      return
    } 
    onAdd({ description, amount: parseFloat(amount), date: date || new Date(year, currentMonth - 1, 1), category, expenseType });
    clearForm();
    setIsOpen(false);
  };

  const clearForm = () => {
    setDescription('');
    setAmount('');
    setDate('');
    setCategory('Bills');
    setExpenseType('');
  };

  const handleRadioChange = (value) => {
    setExpenseType(value);
    setDisableRadioGroup(false);
  };

  return (
    <div className="flex justify-center">
      <Button gradientMonochrome="teal" onClick={() => setIsOpen(true)}>Add Expense</Button>
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header>Add Expense</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="description" className="block font-medium">
                Description<span style={{ color: 'red' }}>*</span>
              </label>
              <TextInput
                id="description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="amount" className="block font-medium">
                Amount<span style={{ color: 'red' }}>*</span>
              </label>
              <TextInput
                id="amount"
                type="number"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="date" className="block font-medium">
                Date <span className="text-gray-500">(optional, defaults to first day of current month)</span>
              </label>
              <TextInput
                id="date"
                type="date"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="category" className="block font-medium">
                Category
              </label>
              <Select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Bills">Bills</option>
                <option value="Food">Food</option>
                <option value="Groceries">Groceries</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Other">Other</option>
              </Select>
            </div>
            <div>
              <div>
                {radioOptions.map((option, key) => (
                  <div key={key}>
                    <input
                      type="radio"
                      id={`radio-${key}`}
                      name="expenseType"
                      value={option.value}
                      onChange={() => handleRadioChange(option.value)}
                      disabled={disableRadioGroup}
                    />
                    <label htmlFor={`radio-${key}`}>{option.label}</label>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "teal" }} onClick={handleSubmit}>Submit</Button>
          <Button color="gray" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddExpense;
