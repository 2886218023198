// src/components/Header.js
import React, { useContext } from "react";
import { Navbar, Dropdown, Button } from "flowbite-react";
import { MonthContext } from "../context/MonthContext";
import { HiOutlineChevronRight } from "react-icons/hi";
import "./Header.css";

const Header = ({ setIsOpen }) => {
  const { currentMonth, setCurrentMonth } = useContext(MonthContext);

  const handleMonthChange = (month) => {
    setCurrentMonth(parseInt(month));
  };

  const monthNames = Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );

  return (
    <div className="flex items-center space-x-4 p-4">
      <Button color={"gray"} onClick={() => setIsOpen(true)}>
        <HiOutlineChevronRight />
      </Button>
      <Navbar
        style={{ width: "-webkit-fill-available" }}
        fluid={true}
        rounded={true}
      >
        <Navbar.Brand href="#">
          <span className="self-center whitespace-nowrap text-xl font-semibold">
            Budget Tracker
          </span>
          <div className="dropdown dropdown-container">
            <Dropdown
              label={monthNames[currentMonth - 1]}
              className="p-2 bg-white text-black rounded"
            >
              {monthNames.map((monthName, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleMonthChange(index + 1)}
                >
                  {monthName}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </Navbar.Brand>
      </Navbar>
    </div>
  );
};

export default Header;
