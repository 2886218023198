import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import ApiHandler from '../util/ApiHandler';

const useSubscriptions = () => {
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      setLoading(true);
      setError(null);

      try {
        if (auth.currentUser) {
          const response = await ApiHandler.get(`/subscription/${auth.currentUser.uid}`);
          setSubscription(response);
        }
        else {
          setSubscription({});
        }
      } catch (error) {
        setError('Error fetching subscription for user ', auth.currentUser.uid);
        console.error('Error fetching subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
    // eslint-disable-next-line
  }, [auth.currentUser]);

  return { subscription, setSubscription, loading, error };
};

export default useSubscriptions;
