import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import ApiHandler from '../util/ApiHandler';

const useUser = () => {
  const [user, loading, error] = useAuthState(auth);
  const [loggedInUser, setLoggedInUser] = useState();
  const [_loading, setLoading] = useState(loading);
  const [_error, setError] = useState(error);

  const fetchUser = useCallback(async (userId) => {
    setLoading(true);
    setError(null);

    try {
      if (userId) {
        const response = await ApiHandler.get(`/user/${userId}`);
        setLoggedInUser(response);
      } else {
        setLoggedInUser();
      }
    } catch (error) {
      setError('Error fetching user');
      console.error('Error fetching user:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchUser(user.uid);
    } else {
      setLoggedInUser();
    }
    // eslint-disable-next-line
  }, [user, fetchUser]);

  return { loggedInUser, setLoggedInUser, _loading, _error, fetchUser };
};

export default useUser;
