import React, { useState, useEffect } from 'react';
import { Alert } from 'flowbite-react'
import { HiCheck, HiX, HiExclamation } from "react-icons/hi";
import { Progress } from "flowbite-react";

const BTToast = ({ showToast, type, message }) => {

    const icons = {
        success: HiCheck,
        error: HiX,
        warning: HiExclamation
    };

    const [progress, setProgress] = useState(100);
    useEffect(() => {
        // Calculate the interval time to fill the bar in 5 seconds
        const intervalTime = 50; // milliseconds
        const totalIntervalCount = 2500 / intervalTime; // Total intervals in 5 seconds
        const progressIncrement = 100 / totalIntervalCount; // Increment to fill in each interval

        // Set up an interval to update the progress
        const intervalId = setInterval(() => {
            setProgress((currentProgress) => {
                const updatedProgress = currentProgress - progressIncrement;
                if (updatedProgress <= 0) {
                    clearInterval(intervalId); // Clear interval when progress is complete
                    showToast(false)
                    return 0; // Cap the progress at 0%
                }
                return updatedProgress;
            });
        }, intervalTime);

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    });

    return (
        <Alert
            color={type}
            icon={icons[type]}
            rounded
            additionalContent={<Progress progress={progress} size="sm" color={type === 'success' ? "green" : "red"} style={{ display: "contents" }} />}>
            <span className="font-medium">{message}</span>
        </Alert>
    );
};

export default BTToast;
