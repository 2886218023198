import React from 'react';
import { Table } from 'flowbite-react';
import { format } from 'date-fns';

const ExpenseTable = ({ expenses }) => {
  // Calculate the total amount
  const totalAmount = expenses.reduce((total, expense) => total + expense.amount, 0);

  return (
    <Table striped className="min-w-full bg-white border border-gray-300 rounded-lg shadow">
      <Table.Head className="bg-gray-100 border-b border-gray-300">
        <Table.HeadCell className="bg-blue-900">Description</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900">Amount</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900">Date</Table.HeadCell>
      </Table.Head>
      <Table.Body>
        {expenses.map((expense, index) => (
          <Table.Row key={index} className="border-b border-gray-300">
            <Table.Cell>{expense.description}</Table.Cell>
            <Table.Cell>${expense.amount.toFixed(2)}</Table.Cell>
            <Table.Cell>{format(new Date(expense.date), 'EEEE, MMMM dd')}</Table.Cell>
          </Table.Row>
        ))}
        {/* Total Row */}
        <Table.Row className="bg-gray-100 border-t border-gray-300">
          <Table.Cell className="font-semibold">Total</Table.Cell>
          <Table.Cell className="font-semibold">${totalAmount.toFixed(2)}</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default ExpenseTable;
