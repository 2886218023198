import React, { useState } from 'react';
import { Button, Modal, Avatar } from 'flowbite-react';

const AvatarModal = ({ isOpen, setIsOpen, setSelectedAvatar, saveAvatar }) => {

  const AVATARS = [
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar1.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar2.png',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar3.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar4.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar5.png',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar6.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar7.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar8.png',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar9.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar10.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar11.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar12.png',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar13.jpg',
    'https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/avatars/avatar14.jpg',
  ];

  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);

  const handleAvatarClick = (index, avatar) => {
    setSelectedAvatarIndex(index);
    setSelectedAvatar(avatar);
  };

  return (
    <div className="flex justify-center">
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header>Select an avatar:</Modal.Header>
        <Modal.Body>
          <div className="flex flex-wrap justify-center gap-10">
            {AVATARS.map((avatar, index) => (
              <Avatar
                key={index}
                alt={`Avatar ${index}`}
                img={avatar}
                rounded
                className={`w-16 h-16 rounded-full cursor-pointer ${selectedAvatarIndex === index ? 'ring-2 ring-blue-500' : 'hover:bg-gray-200'
                  }`}
                onClick={() => handleAvatarClick(index, avatar)}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <button onClick={saveAvatar}>Save Avatar</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AvatarModal;
