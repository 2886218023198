import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import ApiHandler from '../util/ApiHandler';
import { useNavigate, Link } from "react-router-dom";
import './Register.css';
import BTToast from './BTToast';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleRegister = async (e) => {
    //will create a new user in firebase, stripe & prisma
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const token = await user.getIdToken();

      //returns a prisma user
      await ApiHandler.post('/register', {
        idToken: token,
        firstName,
        lastName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowSuccess(true);
      navigate("/");
    } catch (error) {
      console.error('Error registering user', error);
      setShowError(true);
    }
  };

  return (
    <div className="register-container">
      {showSuccess && <BTToast showToast={setShowSuccess} type="success" message={'Registration success!'} />}
      {showError && <BTToast showToast={setShowError} type="failure" message={'Error registering user'} />}
      <div className="register-logo">
        <img className='register-img' src="../BudgetCover.png" alt="Budget Tracker Logo" />
      </div>
      <h2>Create an account on Budget Tracker</h2>
      <form className="register-form" onSubmit={handleRegister}>
      <div className="form-group">
          <label htmlFor="fName">First Name</label>
          <input
            type="fname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="lName">Last Name</label>
          <input
            type="lName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-register">Register</button>
      </form>
      <div className="alternative-login">
        <p>Already have an account?<Link to="/login" className="register-link">Login</Link></p>
      </div>
    </div>
  );
};

export default Register;
