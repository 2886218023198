// src/hooks/useExpenses.js
import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import ApiHandler from '../util/ApiHandler';

const useExpenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExpenses = async () => {
      setLoading(true);
      setError(null);

      try {
        if (auth.currentUser) {
          const response = await ApiHandler.get(`/expenses/${auth.currentUser.uid}`);
          setExpenses(response);
        }
        else {
          setExpenses([]);
        }
      } catch (error) {
        setError('Error fetching expenses');
        console.error('Error fetching expenses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchExpenses();
    // eslint-disable-next-line
  }, [auth.currentUser]);

  return { expenses, setExpenses, loading, error };
};

export default useExpenses;
